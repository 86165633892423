<template>
  <!-- 卓猎条款协议 -->
  <div class="agreement">
      <div class="main">
          <!-- <div
              class="content"
              v-html="richHtml"
          ></div> -->
          <div class="center-title">
              认证服务协议
          </div>
          <div class="describe">
              欢迎您使用认证服务!为了更好地为您提供服务，请您在开始使用本服务前,认真阅读并充分理解本协议，
              特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用特殊单项服务的条款、
              法律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。如您未满18周岁，请在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用本服务。
          </div>
          <div class="describe">
              欢迎您使用认证服务!为了更好地为您提供服务，请您在开始使用本服务前,认真阅读并充分理解本协议，
              特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用特殊单项服务的条款、
              法律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。如您未满18周岁，请在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用本服务。
          </div>

          <div class="title">1.定义</div>
          <div class="tl">
              <div>1.1“本服务":即认证服务，系我们基于您的申请及提交的资料和信息为您提供的一项用户身份校验并视情况确定认证结果和认证信息的服务。</div>
              <div>1.2“本协议”∵:即《认证服务协议》，是我们与您就认证服务的使用等相关事项所订立的协议。</div>
              <div>1.3“我们":系指重庆卓猎网企业管理有限公司。</div>
          </div>

          <div class="title">2.授权与许可</div>
          <div class="tl">
              <div>2.1为了向您提供本服务，我们需对您在申请服务过程中所填写或留存的信息进行存储，同时为了准确核验您的身份，尽量防止您的身份被冒用，您授权我们收集您的人脸照片、身份证件等信息。</div>
              <div>2.2为准确验证您的身份,我们需将前款载明的信息提供给合法存有您信息的第三方机构进行比对核验，以便我们核验您的身份真实性。我们会与该等第三方签订协议并要求其做好用户信息安全保障。</div>
          </div>

          <div class="title">3.您的权利义务</div>
          <div class="tl">
              <div>3.1您理解并同意,您提交的资料和信息是作出认证结果的重要依据,您应保证提供给我们的所有资料和信息的真实性、合法性、准确性和有效性，任何资料和信息的变更将可能影响认证结果，您应当在资料和信息变更时及时提出补充认证申请。</div>
              <div>3.2目前我们就本服务不收取任何服务费;如后续本服务需要收费，我们将通过公告的形式告知您收费时间及收费标准。</div>
              <div>3.3您不得将本服务用于中国法律法规所限制或禁止，以及违背道德风俗的领域，否则，因此给我们或及其关联公司造成损害的，你还应足额赔偿公司及其关联公司因此遭受的全部损失。</div>
          </div>

          <div class="title">4.我们的权利义务</div>
          <div class="tl">
              <div>4.1我们应按本协议约定向您提供认证服务。</div>
              <div>4.2我们会根据认证技术的发展及市场风险环境的需要,不断调整完善相应本服务的内容及形式。</div>
              <div>4.3本服务内容仅限于对您提交的资料及信息进行甄别与核实，我们只能在合法权限和合理能力的范围内将对前述资料及信息进行合理、谨慎的形式审查，我们不为认证结果提供任何担保</div>
          </div>

          <div class="title">5.个人信息保护</div>
          <div class="tl">
              <div>5.1保护用户信息是我们的一项基本原则,我们将会采取合理的措施保护您所提交的一切资料及信息。除法律法规规定及本协议约定的情形外，未经您的许可我们不会向我们之外的第三方共享您的认证结果及认证相关信息。</div>
              <div>5.2在本协议许可的范围内，我们及第三方审核机构会对相关资料及信息采用专业加密存储与传输方式进行传输及使用，以保障用户的信息安全。</div>
              <div>5.3我们承诺，将使信息安全保护达到合理的安全水平。为保障您的信息安全,
              我们致力于使用各种安全技术及配套的管理体系来防止您的信息被泄露、毁损或者丢失。同时建立了相关内控制度，
              对可能接触到您的信息的工作人员采取最小够用授权原则，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导。</div>
              <div>5.4请您理解，由于技术水平限制及可能存在的各种恶意手段，有可能因我们可控范围外的因素而出现问题。若不幸发生个人信息安全事件,我们将按照法律法规的要求,
              及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、
              对您的补救措施等。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
              如因我们故意或重大过失导致您的信息泄露并给您造成直接损失的，我们应依法承担赔偿责任。</div>
              <div>5.5除根据法律法规规定及本协议对您的个人信息予以保护外,在处理您的信息时,我们将依据《隐私政策》严格保护您的信息，请您仔细阅读。</div>
          </div>

          <div class="title">6.违约处理</div>
          <div class="tl">
              <div>如果我们发现或收到他人投诉您违反法律法规或本协议约定的,我们有权不经通知随时视行为情节对您的帐号处以包括但不限于拒绝提供本服务、
              终止本服务资质审核、责令用户补充资质审查资料、责令用户修改认证信息、责令用户再次申请认证、
              强制修改帐号名称及认证信息直至取消认证等措施。因此给我们或及其关联公司造成损害的,
              你还应足额赔偿公司及其关联公司因此遭受的全部损失。</div>
          </div>

          <div class="title">7.免责声明</div>
          <div class="title" style="margin-top: 0;">您理解并同意，鉴于计算机、互联网的特殊性，下述情况不属于我们违约:</div>
          <div class="tl fw">
              <div>1)因自然灾害如洪水、地震、瘟疫流行等以及社会事件如战争、动乱、政府行为、电信主干线路中断、网路堵塞、电信部门技术调整和政府管制等导致本服务不能提供或中断;</div>
              <div>4.2我们会根据认证技术的发展及市场风险环境的需要,不断调整完善相应本服务的内容及形式。</div>
              <div>4.3本服务内容仅限于对您提交的资料及信息进行甄别与核实，我们只能在合法权限和合理能力的范围内将对前述资料及信息进行合理、谨慎的形式审查，我们不为认证结果提供任何担保</div>
          </div>

          <div class="title">8.未成年人使用条款</div>
          <div class="tl fw">
              <div>8.1若您是未满18周岁的未成年人,应在监护人监护、指导并获得监护人同意情况下认真阅读本协议后，方可使用本服务。</div>
              <div>8.2我们重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待,并在监护人指导时正确使用本服务。</div>
              <div>8.3未成年用户理解,如因您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而可能引起的全部法律责任。</div>
          </div>

          <div class="title">9.其他</div>
          <div class="tl fw">
              <div>9.1本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国法律。若本协议之任何规定因与中华人民共和国的法律抵触而无效或不可执行，则这些条款将尽可能按照接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。</div>
              <div>9.2本协议的签订地为中华人民共和国北京市海淀区。若您因本协议与公司发生任何争议，双方应尽量友好协商解决;_如协商不成的,您同意应将相关争议提交至本协议签订地的人民法院诉讼解决。</div>
              <div>9.3为给您提供更好的服务或国家法律法规、政策调整，本服务将不时更新与变化，我们会适时对本协议进行修订，这些修订构成本协议的一部分。本协议更新后，我们会发出更新版本，并在更新后的条款生效前通过官方网站公告或其他适当的方式提醒您更新的内容，以便您及时了解本协议的最新版本。如您对修改后的协议条款存有异议的，您有权自主决定选择停止使用本服务。若您在公告约定的生效时间后继续使用本服务的，表示您已充分阅读、理解并接受变更修改后的协议内容。</div>
              <div>9.4本协议中的标题仅为方便及阅读而设，并不影响本协议中任何规定的含义或解释。</div>
          </div>

      </div>
  </div>
</template>

<script>
  export default {
      data() {
        return {
          title:"服务条款",
          backVisible:true,
          richHtml: ``
        }
      },
  }
</script>

<style scoped>
div{
  box-sizing: border-box;
}
.agreement{
    padding-top: 50px;
}
.agreement .main{
    padding: 10px 20px 50px 20px;
}
.agreement .center-title{
    font-size: 22px;
    text-align: center;
    font-weight: 650;
}
.agreement .title{
    margin-top: 10px;
    line-height: 22px;
    color: #222;
    font-size: 16px;
    font-weight: 650;
}
.agreement .describe{
    margin-top: 10px;
    font-size: 13px;
    text-indent:25px;
    line-height: 22px;
}
.tl{
  font-size: 13px;
  text-indent:25px;
  line-height: 22px;
}
.tl.fw{
    /* font-weight: 650; */
}
</style>
